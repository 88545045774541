import Images from './theme/images.js';

const title = 'Robomind Mais';

export default {
  siteName: title,
  siteIcon: 'ion-flash',
  footerText: `${title} @ ${new Date().getFullYear()}`,
  enableAnimatedRoute: false,
  defaultPageSize: 20,
  apiUrl: 'http://yoursite.com/api/',
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  dashboard: '/dashboard',
  siteTitleADM: title,
  siteTitleFranchisee: title,
  collapsedLogo: Images.logoMenu,
  nonCollapsedLogo: Images.logoMenu,
  registerLicence:
    'Mgo+DSMBaFt/QHRqVVhjVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSH5ad0dnWn9cdnxcQA==;Mgo+DSMBPh8sVXJ0S0J+XE9HflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31Td0VqWH1ac3RXQWBVVw==;ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRjUH5cdHdVRGdeVEI=;OTA1NDQ4QDMyMzAyZTM0MmUzME1sU3NwNFYraHFOaTIySHBwdlpRREwvamdYVkpPWWpBS0p5SkI1UWxVc3M9;OTA1NDQ5QDMyMzAyZTM0MmUzMG9NMHgyYWE0T2V0c0lVQWFzaFBua0wyRGh5YW9SNU1aN0RWZlpLUkJnNk09;NRAiBiAaIQQuGjN/V0Z+WE9EaFxKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUViWXZfcnBWRmJUUUFw;OTA1NDUxQDMyMzAyZTM0MmUzMGpUcFVRWUpUOUpVNTVwa3ZqdW0vVGUzaVJCQVpkSXBjZEp1REVFV3JGK0U9;OTA1NDUyQDMyMzAyZTM0MmUzMFJyNFd3UjZNK0VLakh1Y2VLWktRRktCZmxEQjlBZUVMY0EvWnluMzg0Q2c9;Mgo+DSMBMAY9C3t2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRjUH5cdHdVRGhUV0I=;OTA1NDU0QDMyMzAyZTM0MmUzMFUwVTJBRkVjSnRLRlN2ZGVyaGJJTklXSEk1dTFIcWJWMzdFdHVWTm9kYlE9;OTA1NDU1QDMyMzAyZTM0MmUzMFV5RWUwVEhESE5SUWV1bXd1M0k0Nys4M2NjQ3JaR1hmZGNyUkYwZWE1dXM9;OTA1NDU2QDMyMzAyZTM0MmUzMGpUcFVRWUpUOUpVNTVwa3ZqdW0vVGUzaVJCQVpkSXBjZEp1REVFV3JGK0U9',
};
